export class Order {
    productList: Product[] = [];

}

export class Product {
    productId: any;
    productName: string;
    vendorMaterialNumber: string;
    ean: string;
    productPoUom: string;
    quantity: number;
    finalQuantity: number = 0;
    error: boolean = false;
    isAdded: boolean = false;
    imageURL: string;
    price: any;
    currency: string;
    itemNumber: string;
    baseUom:any;
    eanAndUom: any = [];
    productSkuId: any;
    uomCode:any;

}

export class DeliveryLocation {
    houseNumber: String;
    street: String;
    region: String;
    district: String;
    city: String;
    postalCode: String;
    poBox: String;
    country: String;
    languageCode: string;
    shipToPartyId: String;
    partyAddressId: String;
    formattedAddress: String;
    location: String;
}

export class PlaceOrderDTO {
    deliveryLocation: DeliveryLocation;
    selectedProducts: Product[] = [];
    requestedDeliveryDateStr: any;
    customerId: string;
    customerBuId: string;
    communityId: string;
    cancelOrder: boolean;
    editOrder: boolean;
    returnOrder: boolean;
    orderId: string;
    poNumber: string;
    itemNumber: string;
    userId: string;
    userLocale: string;
}

export class ReturnOrder {
    orderId: any;
    poNumber: string;
    orderItemId: any;
    reasonCode: any;
    returnQantity: any;
    returnUom: string;
}

export class Reason {
    reasonCode: any;
    reasonValu: string;
}
