import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SharedService } from '../shared/shared.service';
import { TokenStorageService } from '../shared/token-storage.service';
import { ApiResponse } from './products.model';
@Injectable({
  providedIn: 'root'
})
export class ProductCatalogService {
  loginCache;
  httpHeader;
  httpHeader1;

  constructor(public http: HttpClient, private sharedservice: SharedService, public tokenStorage: TokenStorageService) {
    //   this.loginCache = JSON.parse(this.tokenStorage.getLoginCache());;
    // this.httpHeader = new HttpHeaders({'communityId':this.loginCache.communityId.toString(),'userId': this.loginCache.userId.toString()});
    // this.httpHeader1 = new HttpHeaders({'communityId':this.loginCache.communityId.toString(),'buId': this.loginCache.buId.toString()});

  }
  productCatalogmarketurl: string = "/admin/productcatalog/getMarketId";
  uploadUrl: string = "/admin/productcatalog/upload";
  uploadOldUrl: string = "/admin/productcatalog/uploadold";
  downloadUrl: string = "/admin/productcatalog/downloadTemplate";
  downloadIdUrl: string = "/admin/productcatalog/downloadProductCatalog";
  downloadNmbsIdUrl: string = "/admin/productcatalog/downloadNmbsCatalog";

  getProductDownloadMarket() {
    this.httpHeader1 = new HttpHeaders({ 'communityId': this.loginCache.communityId.toString(), 'buId': this.loginCache.buId.toString() });
    let url = this.sharedservice._hostUrl + this.productCatalogmarketurl;
    return this.http.get(url, { headers: this.httpHeader1 });
  }
  downloadExcel(communityName, file): Observable<any> {
    this.httpHeader = new HttpHeaders({ 'communityId': this.loginCache.communityId.toString(), 'userId': this.loginCache.userId.toString() });
    const body: FormData = new FormData();
    body.append('filename', file);
    body.append('communityName', communityName);
    let url = this.sharedservice._hostUrl + this.downloadUrl;
    return this.http.post(url, body, { responseType: "blob", headers: this.httpHeader });
  }
  uploadExcel(fileToUpload: File, communityName: string): Observable<ApiResponse> {
    let tempUrl = '';
    if (communityName == 'NMBS') {
      tempUrl = this.uploadUrl;
    } else {
      tempUrl = this.uploadOldUrl;
    }
    this.httpHeader = new HttpHeaders({
      'communityId': this.loginCache.communityId.toString(), 'userId': this.loginCache.userId.toString(),
      'buId': this.loginCache.buId
    });
    const url = this.sharedservice._hostUrl + tempUrl;
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload);
    return this.http.post<any>(url, formData, { headers: this.httpHeader });
  }
  downloadExcelId(id, communityName, fileName): Observable<any> {
    this.httpHeader = new HttpHeaders({ 'communityId': this.loginCache.communityId.toString(), 'userId': this.loginCache.userId.toString() });
    let url = this.sharedservice._hostUrl + this.downloadIdUrl + "?buId=" + id;

    const body: FormData = new FormData();
    body.append('filename', fileName);
    body.append('communityName', communityName);
    return this.http.post(url, body, { responseType: "blob", headers: this.httpHeader });
  }
  downloadExcelForNmbs(id,fileName): Observable<any> {
    this.httpHeader = new HttpHeaders({ 'communityId': this.loginCache.communityId.toString(), 'userId': this.loginCache.userId.toString() });
    let url = this.sharedservice._hostUrl + this.downloadNmbsIdUrl + "?buId=" + id;
    const body: FormData = new FormData();
    body.append('filename', fileName);
    return this.http.post(url, body, { responseType: "blob", headers: this.httpHeader });
  }
  uploadExcelForDemo(fileToUpload: File , buId){
    this.httpHeader = new HttpHeaders({
      'communityId': this.loginCache.communityId.toString(),
      'userId': this.loginCache.userId.toString(),
      'buId': buId
    });
    const url = this.sharedservice._hostUrl + this.uploadOldUrl;
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload);
    return this.http.post(url, formData, {responseType: 'blob', headers: this.httpHeader });

  }


}
