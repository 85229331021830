import { Injectable } from '@angular/core';
import { THROW_IF_NOT_FOUND } from '@angular/core/src/di/injector';
import { TokenStorageService } from '../shared/token-storage.service';
import { SharedService } from '../shared/shared.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ProductUpload } from './Manage Products/product.modal';

@Injectable({
  providedIn: 'root'
})
export class GhaamaService {


  httpHeader;

  constructor(public httpClient: HttpClient, public sharedService: SharedService, public tokenStorage: TokenStorageService) { }

  uploadProduct(uploadProduct: FormData) {
   /*  let headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data'
    }); */


    //formData.append('file', filesToUpload);
    let url = this.sharedService._hostUrl + '/catalog';
    return this.httpClient.post(url , uploadProduct, { headers:this.httpHeader});
  }

  searchApi(value: string){
   let headers = new HttpHeaders({
      'gtin': value
    });
    let url = this.sharedService._hostUrl + '/rainforest/gtin';
    return this.httpClient.post(url , {}, { headers: headers});
  }

  getProductMasterFromDb(){
    let headers = new HttpHeaders({
       'Content-Type': 'application/json',
     });
     let url = this.sharedService._hostUrl + '/es/getproductmasterforuploadproduct';
     return this.httpClient.post(url , {}, { headers: headers});
   }

   getProductMasterFromES(){
    /* let headers = new HttpHeaders({
       'Content-Type': 'application/json',
     });
     let url = this.sharedService._hostUrl + '/es/getproductmasterforuploadproduct';
     return this.httpClient.post(url , {}, { headers: headers});
 */
     const url = SharedService._hostUrlExternalApi + '/master2/_search/matchAll';

    //};
     // write header and add Access-Control-Allow-Origin: http://localhost:4200


     this.httpClient.get<any>(url).subscribe(
       response => {
        const hits = response.hits && response.hits.hits || [];
         return hits.map(hit => hit._source).filter((value, index, self) => self.indexOf(value) === index);
       },
       error => {
         console.error('Error while searching:', error);
         return [];
       }
     );

   }

   getProducts(){
    /* let headers = new HttpHeaders({
       'Content-Type': 'application/json',
     });
     let url = this.sharedService._hostUrl + '/es/getproductmasterforuploadproduct';
     return this.httpClient.post(url , {}, { headers: headers});
 */
     const url = SharedService._hostUrlExternalApi + '/master2/_search/matchAll';

     // write header and add Access-Control-Allow-Origin: http://localhost:4200


     this.httpClient.get<any>(url).subscribe(
       response => {
        const hits = response.hits && response.hits.hits || [];
         return hits.map(hit => hit._source).filter((value, index, self) => self.indexOf(value) === index);
       },
       error => {
         console.error('Error while searching:', error);
         return [];
       }
     );

   }

   getAddresses(){
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    let url = this.sharedService._hostUrl + '/fetchAllPartyForProduct';
    return this.httpClient.get(url , { headers: headers});
   }

   fetchAllProducts(pageNo){
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'pageNo': pageNo,
    });
    let url = this.sharedService._hostUrl + '/fetchAllProducts';
    return this.httpClient.get(url , { headers: headers});
   }

   fetchAllProductsForApproval(pageNo: string) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'pageNo': pageNo,
    });
    let url = this.sharedService._hostUrl + '/fetchAllProductsForApproval';
    return this.httpClient.get(url , { headers: headers});
  }

  approveProduct(skuId: number) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'productId': skuId.toString(),
    });
    let url = this.sharedService._hostUrl + '/approveProduct';
    return this.httpClient.get(url , { headers: headers});
  }
  rejectProduct(skuId: number) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'productId': skuId.toString(),
    });
    let url = this.sharedService._hostUrl + '/rejectProduct';
    return this.httpClient.get(url , { headers: headers});
  }

}
