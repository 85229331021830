import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SharedService } from 'src/app/shared/shared.service';

@Injectable({
  providedIn: 'root'
})
export class AddNodeService {
  getBuLevelDataUrl: string;
  searchBuByLevelUrl: string;
  getBuLevelsUrl: string = '/userBuLevels';
  parameters;
  saveBuDataUrl;
  headers;
  constructor(public httpClient: HttpClient, public sharedService: SharedService) {

  }

  getBuLevelData(body) {
    this.headers = new HttpHeaders();
    this.headers.append('Content-Type', 'application/json');
    this.getBuLevelDataUrl = this.sharedService._hostUrl + '/userBuLevels';
    return this.httpClient.post(this.getBuLevelDataUrl, body, { headers: this.headers });
  }
  searchBuByLevelData(body): Observable<any> {
    this.headers = new HttpHeaders();
    this.headers.append('Content-Type', 'application/json');
    this.searchBuByLevelUrl = this.sharedService._hostUrl + '/searchBuByLevel';
    return this.httpClient.post(this.searchBuByLevelUrl, body, { headers: this.headers });
  }

  saveBuData(addNodeSaveData): Observable<any> {
    this.saveBuDataUrl = this.sharedService._hostUrl + '/createBu';
    return this.httpClient.post(this.saveBuDataUrl, addNodeSaveData, { responseType: 'text' });
  }

  editBuData(addNodeSaveData): Observable<any> {
    this.saveBuDataUrl = this.sharedService._hostUrl + '/editBu';
    return this.httpClient.post(this.saveBuDataUrl, addNodeSaveData, { responseType: 'text' });
  }

  getHubLevelFromHierarchyAlias(body): Observable<any> {
    this.saveBuDataUrl = this.sharedService._hostUrl + '/getHubLevelFromHierarchyAlias';
    return this.httpClient.post(this.saveBuDataUrl, body);
  }

  getCommunityLevelCount(body): Observable<any> {
    const getCommunityByIdLevelCountURL = this.sharedService._hostUrl + '/getCommunityById';
    return this.httpClient.post(getCommunityByIdLevelCountURL, body);
  }

  getMarketForSelectedBu(addNodeSaveData): Observable<any> {
    return this.httpClient.post(this.sharedService._hostUrl + '/getMarketForBu', addNodeSaveData, { responseType: 'json' });
  }

  createBUInit(body): Observable<any> {
    const url = this.sharedService._hostUrl + '/createBUInit';
    return this.httpClient.post(url, body, { responseType: 'json' });
  }

  isMarketAdmin(communityId, buId) {
    let header = new HttpHeaders({
      'communityId': communityId.toString(),
      'buId': buId
    });
    let url = this.sharedService._hostUrl + "/isMarketAdmin";
    return this.httpClient.get(url, { headers: header });

  }

}
